/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import About from "./about"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 600,
        }}
      >
        <main>{children}</main>
        <About />
        <footer className="footer">created by Anton & Alon ©</footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

// const targets = document.querySelectorAll("img")

// const lazyLoad = target => {
//   const io = new IntersectionObserver((entries, observer) => {
//     console.log(entries)
//     entries.forEach(entry => {
//       console.log("😍")

//       if (entry.isIntersecting) {
//         const img = entry.target
//         const src = img.getAttribute("data-lazy")

//         img.setAttribute("src", src)
//         img.classList.add("fade")

//         observer.disconnect()
//       }
//     })
//   })

//   io.observe(target)
// }

// targets.forEach(lazyLoad)
