import React from "react"

const About = () => (
  <article className="container about-us">
    <h1 className="about-us-title">About us</h1>
    <h1>
      youtuber.life is your home for discovering YouTube channels and new
      content.
    </h1>
    <p>We hope you find this site helpful</p>
    <p>Enjoy ;)</p>
  </article>
)

export default About
